<template>
  <component :is="tag" :class="classes">
    <slot />
  </component>
</template>

<script>
export default {
  props: {
    asSpan: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: 'white',
      validator: (value) => {
        return ['white'].includes(value)
      }
    },
    size: {
      type: String,
      default: 'medium',
      validator: (value) => {
        return ['small', 'medium', 'large', 'extraLarge'].includes(value)
      }
    },
    weight: {
      type: String,
      default: 'normal',
      validator: (value) => {
        return ['normal', 'bold'].includes(value)
      }
    }
  },

  computed: {
    tag () {
      return this.asSpan ? 'span' : 'div'
    },

    classes () {
      return [
        'commonText',
        `commonText_${this.size ? this.size : 'medium'}`,
        `commonText_${this.color ? this.color : 'white'}`,
        `commonText_${this.weight ? this.weight : 'normal'}`
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.commonText {
  &_small {
    font-size: 14px;
    line-height: 21px;
  }

  &_medium {
    font-size: 16px;
    line-height: 24px;
  }

  &_large {
    font-size: 18px;
    line-height: 27px;
  }

  &_extraLarge {
    font-size: 20px;
    line-height: 30px;
  }

  &_white {
    color: #fff;
  }

  &_normal {
    font-weight: 400;
  }

  &_bold {
    font-weight: 700;
  }
}
</style>
